<template>
  <v-sheet>
    <cit-data-table
      :headers="headers"
      disable-actions
      resource-path="feed-connectors/nod/fault-codes"
    >


      <template v-slot:actions>
        <import/>
      </template>

    </cit-data-table>

  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import Import from '@/views/FeedConnectors/Nod/NodFaults/Import'

export default {
  components: {
    Import,
    ActionDelete,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'Id Nod',
          value: 'nod_id',
          width: '120',
          filterType: 'text',
          sortable: true
        },
        {
          text: 'Denumire',
          value: 'description',
          filterType: 'text',
          sortable: true
        }
      ]
    }
  }
}
</script>
