<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <new-button
        v-bind="attrs"
        v-on="on"
        button-label="Importa Defecte"
        @click="importFaults"
      />
    </template>
    <span>Importa Defecte</span>
  </v-tooltip>
</template>

<script>
import NewButton from '@/components/general-form/NewButton'

export default {
  name: 'ImportCategories',
  components: {NewButton},
  inject: ['table'],
  methods: {
    importFaults () {
      this.table.startLoading()
      this.$http.post('feed-connectors/nod/fault-codes')
        .finally(() => {
          this.table.loadItems()
        })
    }
  }
}
</script>
